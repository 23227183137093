import { Progress, Button, Heading, VStack } from "@chakra-ui/react";
import OptionsQuestion from "../components/Onboard/OptionsQuestion";
import FullpageSection from "../components/Onboard/FullpageSection";
import OnboardMsgSection from "../components/Onboard/OnboardMsgSection";
import PreviewQuestion from "../components/Onboard/PreviewQuestion";
import ShortAnswer from "../components/Onboard/ShortAnswer";
import { animateScroll as scroll } from "react-scroll";
import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useAuth } from "../context/AuthContext";
import { makeNewUser } from "../firebase/onboarding";
import { OPTION_COLORS } from "../constants/Colors";
import { useHistory } from "react-router-dom";
import "../styles/Global.css";

function OnboardForm() {
  const NUM_QUESTIONS = 6;

  const [progress, setProgress] = useState(0);
  const { currentUser } = useAuth();
  const history = useHistory();

  const moveSectionDown = () => {
    let currScrollPos = document.documentElement.scrollTop || document.body.scrollTop;
    var i = 0;
    for (; i < NUM_QUESTIONS; i++) {
      if (currScrollPos < window.innerHeight * i - 100) {
        break;
      }
    }
    scroll.scrollTo(window.innerHeight * i);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // if (buttonMessage) {
      //   ReactGA.event({
      //     category: "Navigation",
      //     action: "Enter",
      //     label: buttonMessage,
      //   });
      // }
      moveSectionDown();
    }
  };

  const onScroll = (e) => {
    let currScrollPos = document.documentElement.scrollTop || document.body.scrollTop;
    setProgress((currScrollPos / (window.innerHeight * NUM_QUESTIONS)) * 100);
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <div className="App gradient-muted">
      <div style={{ position: "fixed", top: "0", width: "100%" }}>
        <Progress size="xs" value={progress} />
      </div>
      <Formik
        initialValues={{
          name: "",
          newsletter_name: "",
          readers: "",
          question: "",
          options: ["Bad", "Meh", "Good", "Awesome"],
        }}
        onSubmit={(values, actions) => {
          setTimeout(async () => {
            let first_name = values.name.split(" ")[0];
            let last_name = values.name.split(" ").length > 1 ? values.name.split(" ")[1] : "";
            if (values.options.length < 2 || values.options.length > 6) {
              alert("The number of options must be between 2 and 6");
            } else if (
              values.name === "" ||
              values.newsletter_name === "" ||
              values.readers === "" ||
              values.question === ""
            ) {
              alert("Please fill in all fields");
            } else {
              await makeNewUser(
                values.newsletter_name,
                values.readers,
                first_name,
                last_name,
                currentUser.email,
                values.options,
                OPTION_COLORS[values.options.length - 3],
                values.question,
                currentUser.uid
              );
              //first_name, last_name, values.newsletter_name, values.options, OPTION_COLORS[values.options.length - 3], values.question, currentUser.id
              history.push("/google-form");
            }
            actions.setSubmitting(false);
          }, 500);
        }}
      >
        {(props) => (
          <Form>
            <FullpageSection>
              <ShortAnswer
                prompt="What is your full name?"
                subText="Glad to have you with us"
                placeholder="Full Name"
                id="name"
              />
            </FullpageSection>
            <FullpageSection>
              <ShortAnswer
                prompt="What's the name of your newsletter?"
                subText="We just want to get to know you"
                placeholder="Newsletter Name"
                id="newsletter_name"
              />
            </FullpageSection>
            <FullpageSection>
              <ShortAnswer
                prompt="Approximately how many readers do you have?"
                subText="Gives us a sense of how we can be most helpful"
                placeholder="200"
                id="readers"
              />
            </FullpageSection>
            <FullpageSection>
              <PreviewQuestion
                prompt="This is how readers will be able to use the Feedback Footer on your newsletter!"
                subText="Following this will be your preferences for the question and options"
                imageUrl="https://brizy.b-cdn.net/media/original/c4ded5bfc03bcb9f472053cf1c1cf676.gif"
              />
            </FullpageSection>
            <FullpageSection>
              <ShortAnswer
                prompt="What question would you like to ask in your newsletter?"
                subText="You can always change this when you type it into your newsletter"
                placeholder="What did you think?"
                id="question"
              />
            </FullpageSection>
            <FullpageSection>
              <OptionsQuestion
                prompt="What options would you like for your question?"
                subText="We find that 4 to 5 options works best"
                values={props.values}
              />
            </FullpageSection>

            <OnboardMsgSection>
              <VStack>
                <Heading size="lg">We are so excited to be a part of your newsletter's journey!</Heading>

                <img
                  src="/welcome_onboard.gif"
                  alt="rocket GIF"
                  width="50%"
                  style={{ marginTop: "16px", marginBottom: "16px" }}
                />

                <Button size="lg" isLoading={props.isSubmitting} type="submit">
                  Submit
                </Button>
              </VStack>
            </OnboardMsgSection>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default OnboardForm;
