import * as DATA from "../constants/FirebaseNodes";
import { db } from "../firebase/Firebase";

// VERSION 1
export const makeNewUser = async (
  newsletter_name,
  num_readers,
  first_name,
  last_name,
  email,
  options,
  color_scheme,
  question_text,
  uid
) => {
  // make question
  var new_question_ref = makeMCQuestionType(options, color_scheme, question_text);
  if (!new_question_ref) {
    console.log("An error occurred creating a new MC Question");
    alert("An error occurred creating a new question. Please contact tryfeedbee@gmail.com so we can fix it ASAP.");
    return null;
  }
  var new_question_id = new_question_ref.key;

  // make newsletter
  var new_newsletter_ref = await makeNewNewsletter(newsletter_name, new_question_id, num_readers);
  if (!new_newsletter_ref) {
    console.log("An error occurred creating a new Newsletter");
    alert("An error occurred creating a new newsletter. Please contact tryfeedbee@gmail.com so we can fix it ASAP.");
    return null;
  }
  var new_newsletter_id = new_newsletter_ref.key;

  // make newsletter writer
  return makeNewNewsletterWriter(first_name, last_name, email, new_newsletter_id, uid);
};

// VERSION 1
export const makeNewNewsletter = async (newsletter_name, active_question_id, num_readers) => {
  let params_list = [newsletter_name, active_question_id, num_readers];
  for (let i = 0; i < params_list.length; i++) {
    if (params_list[i] === undefined || params_list[i] === "" || params_list[i] === null) {
      console.log("Error in makeNewNewsletter parameters");
      return null;
    }
  }

  // create Google Form
  var google_form_data = await fetch(
    "https://script.google.com/macros/s/AKfycbwZKJGzQyBBYAp2aDo-eY4buq-VPc6BD4rNKL_Gh6Loo1REOVYEa4YSnGPB8jCtui0C6g/exec",
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
    }
  )
    .then((response) => response.json())
    .then((data) => data);

  if (!google_form_data) {
    return null;
  }
  console.log(google_form_data);

  var newsletter_ref = db.ref(`${DATA.NEWSLETTER_REF}`);

  return newsletter_ref.push({
    newsletter_name: newsletter_name,
    has_paid: false,
    active_question_id: active_question_id,
    question_type_ids: [active_question_id],
    num_readers: num_readers ? num_readers : "",
    form_edit_url: google_form_data.edit_url,
    form_id: google_form_data.form_id,
    redirect: google_form_data.published_url,
  });
};

// VERSION 1
export const makeNewNewsletterWriter = (first_name, last_name, email, newsletter_id, uid) => {
  // production function (make copy to use for testing)
  let params_list = [first_name, email, newsletter_id, uid];
  for (let i = 0; i < params_list.length; i++) {
    if (params_list[i] === undefined || params_list[i] === "" || params_list[i] === null) {
      console.log("Error in makeNewNewsletterWriter parameters");
      return null;
    }
  }
  var ref = db.ref(`${DATA.NEWSLETTER_WRITER_REF}/${uid}`);
  var newsletter_ref = db.ref(`${DATA.NEWSLETTER_REF}`);

  ref.update({
    first_name: first_name,
    last_name: last_name,
    email: email,
    newsletter_id: newsletter_id,
  });

  return newsletter_ref
    .child(newsletter_id)
    .once("value")
    .then((snap) => {
      let value = snap.val();
      if ("newsletter_writer_uids" in value) {
        let temp = value["newsletter_writer_uids"];
        temp.push(ref.key);
        newsletter_ref.child(newsletter_id).update({
          newsletter_writer_uids: temp,
        });
      } else {
        let temp = [ref.key];
        newsletter_ref.child(newsletter_id).update({
          newsletter_writer_uids: temp,
        });
      }
    });
};

// VERSION 1
export const makeMCQuestionType = (options, color_scheme, question_text) => {
  let timestamp = Date.now();
  if (Array.isArray(options) && Array.isArray(color_scheme)) {
    if (options.length !== color_scheme.length) {
      console.log("options length different from color scheme length");
      return null;
    }
    if (question_text) {
      return db.ref(`${DATA.QUESTION_TYPE_REF}`).push({
        options: options,
        color_scheme: color_scheme,
        is_short_response: false,
        is_visible: false,
        last_updated_timestamp: timestamp,
        created_timestamp: timestamp,
        question_text: question_text,
      });
    } else {
      return db.ref(`${DATA.QUESTION_TYPE_REF}`).push({
        options: options,
        color_scheme: color_scheme,
        is_short_response: false,
        is_visible: false,
        last_updated_timestamp: timestamp,
        created_timestamp: timestamp,
      });
    }
  } else {
    console.log("options and/or color_scheme are not arrays");
    return null;
  }
};

export const getFormLink = (uid) => {
  if (uid === undefined || !uid || uid === "") {
    alert("Issue has occured getting form link. Please contact tryfeedbee@gmail.com for an immediate fix.");
    return null;
  }
  var ref = db.ref(`${DATA.NEWSLETTER_WRITER_REF}/${uid}`);
  var newsletter_ref = db.ref(`${DATA.NEWSLETTER_REF}`);
  return ref
    .once("value")
    .then((snap) => {
      return snap.val()["newsletter_id"];
    })
    .then((newsletter_id) => newsletter_ref.child(newsletter_id).once("value"))
    .then((snap2) => snap2.val().form_edit_url);
};

export const userInDB = async (uid) => {
  let ref = db.ref(`${DATA.NEWSLETTER_WRITER_REF}/${uid}`);
  let v = await ref.once("value").then((snap) => {
    let val = snap.val();
    if (val !== null && val !== undefined && val !== "") {
      return true;
    }
    return false;
  });
  return v;
};
