import React, { useContext } from "react";
import { db } from "../firebase/Firebase";

const FirebaseContext = React.createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

// Allows UserData to be used throughout multiple components!
export function FirebaseProvider({ children }) {
  // returns firebase db object
  function getDB() {
    return db;
  }

  // returns firebase auth object
  function getAuth() {
    return db.auth();
  }

  const value = {
    getAuth,
    getDB,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
}
