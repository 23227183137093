import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { userInDB } from "../firebase/onboarding";

export default function OnboardingRoute({ component: Component, home: Home = "/", dashboard: Dash = "/dashboard", ...rest }) {
  const { currentUser } = useAuth();

  const [onboarded, setOnboarded] = useState(false)
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function checkIfInDB() {
      if (await userInDB(currentUser.uid)) {
        setOnboarded(true);
      }
      setLoaded(true);
    }
    checkIfInDB();
  }, [onboarded, currentUser.uid])

  return (
    <Route
      {...rest}
      render={(props) => {
        return loaded ? (currentUser ? (onboarded ? <Redirect to={Dash} /> : <Component {...props} />) : <Redirect to={Home} />) : null;
      }}
    ></Route>
  );
}
