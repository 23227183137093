import { ChakraProvider } from "@chakra-ui/react";
import { Suspense } from "react";
import { lazy } from 'react-lazy-no-flicker';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "./components/Loading";
import Navbar from "./components/Navbar/Navbar2";
import * as ROUTES from "./constants/Routes";
import { AuthProvider } from "./context/AuthContext";
import OnboardingRoute from "./hooks/OnboardingRoute";
import PrivateRoute from "./hooks/PrivateRoute";
import GoogleForm from "./pages/GoogleForm";
import OnboardForm from "./pages/OnboardForm";
import "./styles/Global.css";
const Home = lazy(() => import("./pages/Home.js"));
const Dashboard = lazy(() => import("./pages/Dashboard.js"), { time_before_fallback: 100, minimum_fallback_time: 1000 });
const Support = lazy(() => import("./pages/Support.js"));
const Verify = lazy(() => import("./pages/Verification.js"));

function App() {
  return (
    <Router>
      <AuthProvider>
        <ChakraProvider>
          <Suspense fallback={<Loader />}>
            {/* <Dashboard/> */}
            <Navbar />
            <Switch>
              <Route exact path={ROUTES.HOME} component={Home} />
              <Route path={ROUTES.SUPPORT} component={Support} />
              <Route path={ROUTES.VERIFY} component={Verify} />
              <Route path={ROUTES.GOOGLE_FORM} component={GoogleForm} />
              <OnboardingRoute
                path={ROUTES.ONBOARD}
                dashboard={ROUTES.DASHBOARD}
                component={OnboardForm}
              ></OnboardingRoute>
              <PrivateRoute
                path={ROUTES.DASHBOARD}
                onboarding={ROUTES.ONBOARD}
                component={Dashboard}
              ></PrivateRoute>
            </Switch>
          </Suspense>
        </ChakraProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
