import React from "react";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as ROUTES from "../../constants/Routes";

export const SidebarData = [
  {
    title: "Home",
    path: ROUTES.HOME,
    icon: <AiIcons.AiFillHome />,
    cName: "nav-text",
  },
  {
    title: "Dashboard",
    path: ROUTES.DASHBOARD,
    icon: <IoIcons.IoIosPaper />,
    cName: "nav-text",
  },
  // {
  //   title: "Team",
  //   path: "#team",
  //   icon: <BiIcons.BiHive />,
  //   cName: "nav-text",
  // },
  // {
  //   title: "Support",
  //   path: ROUTES.SUPPORT,
  //   icon: <IoIcons.IoMdHelpCircle />,
  //   cName: "nav-text",
  // },
];
