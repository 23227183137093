import { usePastDelay } from 'react-lazy-no-flicker';
import "../styles/Loading.css";

export default function ReactLoader() {
  const past_delay = usePastDelay(100);
  if (!past_delay) return null;
  return (
    <div className="container">
      <div className="loader"></div>
    </div>
  );
}
