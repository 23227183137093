import styled from "styled-components";

export const FullpageSectionDiv = styled.section`
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const OnboardMsgSectionDiv = styled.section`
  height: 100vh;
  width: 100%;
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
