import Firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import config from "./config.js";
// Uses .env.local file so when we setup our production environment,
// we won't change the code!

const fb = Firebase.initializeApp(config);
export const db = fb.database();
export const auth = fb.auth();
