import { Button, Center, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import FullpageSection from "../components/Onboard/FullpageSection";
import * as ROUTES from "../constants/Routes";
import { useAuth } from "../context/AuthContext";
import { getFormLink } from "../firebase/onboarding";
import "../styles/Global.css";

function GoogleForm() {

  var { currentUser } = useAuth()

  const [link, setLink] = useState("")

  useEffect(() => {
    if (currentUser) {
      getFormLink(currentUser.uid).then(link => setLink(link));
    }
  }, [currentUser])

  return (
    <div className="App gradient-muted">
      <FullpageSection>
        <div>
          <Heading size="lg" my="4px">
            After clicking an option in your survey, readers will be taken to the form linked below
          </Heading>
          <Text color="gray.500">Click on the link to edit the form for your readers, then click continue</Text>
          <br />
          <Center>
            <Link to={{ pathname: link }} target="_blank">
              <Button
                size="lg"
                mt={4}
              >
                Edit Form
              </Button>
            </Link>
          </Center>
          <Center>
            <Link to={ROUTES.DASHBOARD}>
              <Button
                size="lg"
                mt={4}
              >
                Continue
              </Button>
            </Link>
          </Center>
        </div>
      </FullpageSection>
    </div>
  );
}

export default GoogleForm;
