import React, { useContext, useEffect, useState } from "react";
import * as firebase from "../firebase/Firebase";
import { auth } from "../firebase/Firebase";
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

// Allows UserData to be used throughout multiple components!
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  // takes in an email and password for now could add more things and generates user

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function googleLogin(provider) {
    firebase.db
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        /** @type {firebase.auth.OAuthCredential} */
        // var credential = result.credential;
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = credential.accessToken;
        // The signed-in user info.
        // var user = result.user;
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        console.log(errorCode);
        console.log(errorMessage);
      });
  }

  function signup(email, password) {
    let variable = auth.createUserWithEmailAndPassword(email, password);
    variable.then(async (userCredential) => {
      await userCredential.user.sendEmailVerification();
      auth.signOut();
    });
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }
  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    googleLogin,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };
  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
