// COLORS
export const PRIMARY1 = "#23212C";
export const PRIMARY2 = "#9b6a14";
export const PRIMARY3 = "#FCB43A";


export const OPTION_COLORS = [
  ["#065a70", "#6f9fa8", "#cbe9e8"],
  ["#065a70", "#518895", "#8db8bc", "#cbe9e8"],
  ["#065a70", "#427c8b", "#6f9fa8", "#9cc4c7", "#cbe9e8"],
  ["#065a70", "#397586", "#5d919c", "#81aeb4", "#a6cbcd", "#cbe9e8"],
]