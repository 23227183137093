import React, { useRef, useState } from "react";
import {
  Modal,
  Alert,
  Form,
  Container,
  Button,
} from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/Routes";

function SignupModal(props) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault(); // prevents refreshing
    // credential checks
    if (passwordRef.current.value !== passwordConfirmRef.current.value)
      return setError("Passwords do not match");
    else if (passwordRef.current.value.length < 6) {
      return setError("Passwords must be longer than 5 characters");
    }

    try {
      setError("");
      await signup(emailRef.current.value, passwordRef.current.value);
      history.push(ROUTES.VERIFY);
      props.onHide();
    } catch {
      setError("Failed to create an account");
    }
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="text-center">
        <Container className="subtitle">Sign Up</Container>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              ref={emailRef}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              ref={passwordRef}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Confirm Password"
              ref={passwordConfirmRef}
              required
            />
          </Form.Group>
          <Button className="submit-btn" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
export default SignupModal;
