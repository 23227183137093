import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { userInDB } from "../firebase/onboarding";

export default function PrivateRoute({ component: Component, home: Home = "/", onboarding: Onboard = "/onboarding", ...rest }) {
  const { currentUser } = useAuth();

  const [onboarded, setOnboarded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function checkIfInDB() {
      if (currentUser && await userInDB(currentUser.uid)) {
        setOnboarded(true);
      }
      setLoaded(true);
    }
    checkIfInDB();
  }, [currentUser, onboarded])

  return (
    <Route
      {...rest}
      render={(props) => {
        return loaded ? (currentUser ? (onboarded ? <Component {...props} /> : <Redirect to={Onboard} />) : <Redirect to={Home} />) : null;
      }}
    ></Route>
  );
}
