import React, { useRef, useState } from "react";
import {
  Alert, Button, Container, Form, Modal
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/Routes";
import { useAuth } from "../../context/AuthContext";

function LoginModal(props) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault(); // prevents refreshing
    try {
      setError("");
      await login(emailRef.current.value, passwordRef.current.value);
      history.push(ROUTES.ONBOARD);
      props.onHide();
    } catch {
      setError("Failed to Log In");
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="text-center">
        <Container className="subtitle">Log In</Container>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              ref={emailRef}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              ref={passwordRef}
              required
            />
          </Form.Group>
          <Button className="submit-btn" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
