import { Button, FormControl, Heading, Input, Text } from "@chakra-ui/react";
import { Field } from "formik";

function ShortAnswer({ prompt, subText, placeholder, id }) {
  return (
    <div className="App">
      <Heading size="lg" my="4px">
        {prompt}
      </Heading>
      {subText ? <Text color="gray.600">{subText}</Text> : null}
      <br />
      <Field name={id}>
        {({ field, form }) => (
          <FormControl>
            <Input {...field} placeholder={placeholder} id={id} />
          </FormControl>
        )}
      </Field>
      <br />
      <Text color="gray.500" style={{ marginBottom: "8px" }}>
        <Button size="xs">Enter &crarr;</Button> to move ahead
      </Text>
      <Text color="gray.500">
        <Button size="xs">Scroll &uarr;</Button> to change previous
      </Text>
    </div>
  );
}

export default ShortAnswer;
