// Auth Routes
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const PROFILE = "/p/:username";
export const NOT_FOUND = "/not-found";
export const PASSWORD_CHANGE = "/change-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const VERIFY = "/verification";
// Main Routes
export const HOME = "/";
export const DASHBOARD = "/dashboard";
export const TEAM = "/the-hive";
export const SUPPORT = "/support";
export const ONBOARD = "/onboard";
export const ONBOARD_FORM = "/onboardtest"
export const GOOGLE_FORM = "/google-form"
