import { CloseIcon } from "@chakra-ui/icons";
import { Button, FormControl, Heading, Input, InputGroup, InputRightAddon, Text } from "@chakra-ui/react";
import { Field, FieldArray } from "formik";

function OptionsQuestion({ prompt, subText, values }) {
  return (
    <div className="App">
      <Heading size="lg" my="4px">
        {prompt}
      </Heading>
      <Text color="gray.500">{subText}</Text>
      <br />

      <FieldArray name="options">
        {({ insert, remove, push }) => {
          return (
            <>
              {values.options.length > 0 &&
                values.options.map((word, index) => (
                  <Field name={`options.${index}`} key={index}>
                    {({ field, form }) => (
                      <FormControl>
                        <InputGroup my="8px">
                          <Input {...field} sx={{ outline: "none" }} />
                          <InputRightAddon
                            children={<CloseIcon h={2} color="white" />}
                            onClick={() => {
                              remove(index);
                            }}
                          />
                        </InputGroup>
                      </FormControl>
                    )}
                  </Field>
                ))}
              <Button w="100%" onClick={() => push("")}>
                +
              </Button>
            </>
          );
        }}
      </FieldArray>

      <br />
      <Text mt="30px" color="gray.500" style={{ marginBottom: "8px" }}>
        <Button size="xs">Enter &crarr;</Button> to move ahead
      </Text>
      <Text color="gray.500">
        <Button size="xs">Scroll &uarr;</Button> to change previous
      </Text>
    </div>
  );
}

export default OptionsQuestion;
