import { Button, Center, Heading, Image, Text } from "@chakra-ui/react";

function PreviewQuestion({ prompt, subText, imageUrl }) {
  return (
    <div className="App">
      <Heading size="lg" my="4px">
        {prompt}
      </Heading>
      {subText ? <Text color="gray.500">{subText}</Text> : null}
      <br />
      <Center>
        <Image src={imageUrl} boxShadow="xl" boxSize={["100%", "90%", "50%"]} />
      </Center>
      <br />
      <Text color="gray.500" style={{ marginBottom: "8px" }}>
        <Button size="xs">Enter &crarr;</Button> to move ahead
      </Text>
      <Text color="gray.500">
        <Button size="xs">Scroll &uarr;</Button> to change previous
      </Text>
    </div>
  );
}

export default PreviewQuestion;
