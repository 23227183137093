import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import "../../styles/Navbar2.css";
import { Button } from "../Buttons";
import LoginModal from "./LoginModal";
import { SidebarData } from "./SidebarData";
import SignupModal from "./SignupModal";

function Navbar() {
  // Signup and Login Pop ups
  const [signinModalShow, setsigninModalShow] = React.useState(false);
  const [loginModalShow, setloginModalShow] = React.useState(false);

  const { currentUser, logout } = useAuth();
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);
    return () => window.removeEventListener("resize", showButton);
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="navbar-nbs">
          <div className="navbar-container container-nbs">
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img alt="Icon" src="./small-darkmode.png" className="navbar-icon"></img>
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              {SidebarData.map((item, index) => {
                return item.title === "Dashboard" && !currentUser ? null : (
                  <li key={index} className="nav-item">
                    <Link
                      to={item.path}
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
              {/* HARD CODED DATA :0 */}
              <li className="nav-item">
                <a href="#team" className="nav-links" onClick={closeMobileMenu}>
                  Team
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="mailto:tryfeedbee@gmail.com?subject=Hey there 👋"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Contact
                </a>
              </li>
              {currentUser ? (
                <>
                  {button ? (
                    <div className="btn-link title">
                      <Button onClick={logout} buttonStyle="btn--outline">
                        Log Out
                      </Button>
                    </div>
                  ) : (
                    <div className="btn-link subtitle">
                      <Button
                        buttonStyle="btn--outline"
                        buttonSize="btn--mobile"
                      >
                        Log Out
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <li className="nav-btn">
                    {button ? (
                      <div
                        onClick={() => setsigninModalShow(true)}
                        className="btn-link title"
                      >
                        <Button buttonStyle="btn--outline">Sign Up</Button>
                      </div>
                    ) : (
                      <div
                        onClick={() => setsigninModalShow(true)}
                        className="btn-link subtitle"
                      >
                        <Button
                          buttonStyle="btn--outline"
                          buttonSize="btn--mobile"
                          onClick={closeMobileMenu}
                        >
                          Sign Up
                        </Button>
                      </div>
                    )}
                  </li>
                  <li className="nav-btn">
                    {button ? (
                      <div
                        onClick={() => setloginModalShow(true)}
                        className="btn-link title"
                      >
                        <Button buttonStyle="btn--outline">Log in</Button>
                      </div>
                    ) : (
                      <div
                        onClick={() => setloginModalShow(true)}
                        className="btn-link title"
                      >
                        <Button
                          buttonStyle="btn--outline"
                          buttonSize="btn--mobile"
                          onClick={closeMobileMenu}
                        >
                          Login
                        </Button>
                      </div>
                    )}
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
        <SignupModal
          show={signinModalShow}
          onHide={() => setsigninModalShow(false)}
        />
        <LoginModal
          show={loginModalShow}
          onHide={() => setloginModalShow(false)}
        />
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
