const firebaseConfig = {
  apiKey: "AIzaSyCYrmd3Rt1oDTPtM0NG4JxkSX3YOrcdJ1g",
  authDomain: "feedbeev1-production.firebaseapp.com",
  databaseURL: "https://feedbeev1-production-default-rtdb.firebaseio.com",
  projectId: "feedbeev1-production",
  storageBucket: "feedbeev1-production.appspot.com",
  messagingSenderId: "917397741168",
  appId: "1:917397741168:web:b3c4478aa3999dfdbd1eb5",
  measurementId: "G-M7XMLX4TWZ"
};
export default firebaseConfig;
